import ben from "../../assets/ben.jpg";
import tim from "../../assets/tim.jpg";

export default [
  {
    id: 0,
    src: ben,
    alt: "ben",
    title: "Ben",
    description:
      "Co-Founder, CFO, CMO, CTO, Director of Creativity, Lead Technician, Potter",
    size: "16rem",
  },
  {
    id: 1,
    src: tim,
    alt: "tim",
    title: "Tim",
    description:
      "Co-Founder, CEO, COO, CCCP, Instructor, Head of Facilities Maintenance, Potter",
    size: "16rem",
  },
];
